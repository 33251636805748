<template>
  <div class="game-landing-page-header">
  </div>
  <div class="section">
    <!-- Main content (70% width) -->
    <div class="main-content">
      <!-- Show loader while fetching game data -->
      <div v-if="loading" class="loader">Loading...</div>

      <!-- Show game details once data is fetched -->
      <div v-else>
        <GameApplicationProposalDetails />
      </div>
    </div>

    <!-- Right-hand sidebar (30% width) - only show once page is loaded -->
    <div v-if="!loading" class="right-sidebar">
      <div class="box">
        <div class="box-header-img"></div>
        <div class="like-box">
          <div class="title">Love this game? Give it a 'like'!</div>
          <div class="sub-title">
            <div style="display: flex; flex-direction: row; align-items: center; margin-top: 10px;">
              <div style="padding-right: 10px;">{{ likeCount }}</div>
              <div class="button-heart" :class="{ 'liked': isLiked }">
                <img :style="{ filter: isLiked ? 'brightness(0.5) sepia(100%)' : '' }"
                  src="@/assets/images/icons/heart-outlined.svg" alt="Heart Icon" @click="handleLike" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRoute, useRouter } from 'vue-router';
import GameApplicationProposalDetails from './gameApplicationProposalDetails.vue';
import { getGameById , gameRating } from "@/api/agfAPI";

export default {
  name: 'Game',
  setup() {
    const route = useRoute();
    const router = useRouter();
  },
  data() {
    return {
      loading: true,
      likeCount: 0, // Like count state
      isLiked: false, // Is the game liked
    };
  },
  components: {
    GameApplicationProposalDetails
  },
  created() {
    this.fetchGameDataById();
  },
  mounted() {
    this.checkLikeStatus(); // Check if the game is liked when the component is mounted
  },
  methods: {
    async fetchGameDataById() {
      const gameId = 10; // Example game ID
      const gameKey = `game_${gameId}`;

      // Check if game data is in localStorage
      const cachedGameData = localStorage.getItem(gameKey);

      if (cachedGameData) {
        // Load from localStorage
        this.selectedGame = JSON.parse(cachedGameData);
        localStorage.setItem('selectedGameProposal', JSON.stringify(this.selectedGame ));

        this.loading = false;
      } else {
        // Fetch from API if not in localStorage
        try {
          const response = await getGameById(gameId);
          if (response.isSuccess) {
            localStorage.setItem(gameKey, JSON.stringify(response.data));
            this.selectedGame = response.data;
            localStorage.setItem('selectedGameProposal', JSON.stringify(this.selectedGame ));

          }
        } catch (error) {
          console.error('Error fetching game data:', error);
        } finally {
          this.loading = false;
        }
      }
    },
    async handleLike() {
      const gameId = 10; // Example game ID
      const likeKey = `liked_game_${gameId}`;
      let data = {
        gameId:gameId,
      }
      await gameRating(data);
      // Check if the game has already been liked
      if (!localStorage.getItem(likeKey)) {
        this.likeCount += 1;
        this.isLiked = true;

        // Store the like status and like count in localStorage
        localStorage.setItem(likeKey, 'true');
        localStorage.setItem(`likeCount_${gameId}`, this.likeCount);
      } else {
        console.log('You have already liked this game.');
      }
    },
    checkLikeStatus() {
      const gameId = 10; // Example game ID
      const likeKey = `liked_game_${gameId}`;
      const likeCountKey = `likeCount_${gameId}`;

      // Check localStorage for existing like status
      if (localStorage.getItem(likeKey)) {
        this.isLiked = true;
        this.likeCount = parseInt(localStorage.getItem(likeCountKey)) || 0;
      }
    }
  }
}
</script>

<style>
.section {
  display: flex;
  justify-content: space-between;
  margin: 80px auto 150px;
  padding: 50px;
  width: 100%;
  height: 100%;
  /* Make it full height */
}

.box {
  display: flex;

  flex-direction: column;
  align-items: center;

  .like-box {
    border-radius: 30px;
    border: 1px solid var(--dark-grey-stroke, #999F9F);
    background: #FFF;
    gap: 40px;
    width: 400px;
    padding: 30px;

  }

  .title {
    color: var(--main-text, #1C1C1C);
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .sub-title {
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: center;
    color: var(--main-text, #1C1C1C);
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    .button-heart {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 40px;
      height: 40px;
    }

    .button-heart.liked {
      filter: brightness(0.5) sepia(100%);
    }
  }

  .box-header-img {
    display: flex;
    width: 237px;
    height: 256px;
    padding: 0.076px 0.137px 0.254px 0.389px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    background-image: url('@/assets/images/game/gamer_character.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.main-content {
  flex: 8;
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;

}

.right-sidebar {
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
  margin-left: 20px;
}

.like-button {
  background-color: #4CAF50;
  color: white;
  font-size: 16px;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.like-button:hover {
  background-color: #45a049;
}

.loader {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}

@media (max-width: 900px) {
  .section {
    flex-direction: column;
  }

  .right-sidebar {
    order: -1;
    /* Move right-sidebar above main-content */
    width: 100%;
  }

  .main-content {
    width: 100%;
  }

  .game-landing-page-header {
    height: 300px;
    /* Adjust height for smaller screens */
    background-size: contain;
    /* Keep the entire image visible on smaller screens */
  }
}

.game-landing-page-header {
  width: 100%;
  height: 400px;
  background-image: url('@/assets/images/game/game_header.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* Ensures the image covers the container */
  margin-top: 100px;
}
</style>
