<template>
    <div class="container">
        <div class="game-overview">
            <div class="game-overview-title">
                <p>{{ 'Milestones' }}</p>
            </div>
            <div class="game-overview-description">
                <!-- Loop through milestones array -->
                <div v-for="(milestone, index) in milestones" :key="milestone.id" class="milestone-item">
                    <div>
                        <p class="milestone-title">{{ index + 1 }}. {{ milestone.title }} - {{ milestone.deadline }} days</p>
                        <h5 class="milestone-funds">{{ milestone.funds }} USDC</h5>
                    </div>
                    <div id="viewEditor">
                        <div class="quillWrapper">
                            <div class="quill-container">
                                <div class="ql-editor ql-blank">
                                    <div v-html="milestone.description"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <p class="milestone-funds">Funds: {{ milestone.funds }}</p>
                    <p class="milestone-deadline">Deadline: {{ milestone.deadline }} days</p> -->
                </div>
            </div>


        </div>
    </div>
</template>
  
<script>
export default {
    name: 'GameOverview',
    props: {
        milestones: {
            type: Array, // Corrected type to Array
            default: () => []
        },
        isAdmin: {
            type: Boolean, // Assuming videoUrl is a URL
            default: false
        }
    },
    data() {
        return {
            // Your existing data properties
        };
    },
    components: {
        // Your existing components
    },
    computed: {
        // Your existing computed properties
    },
    mounted() {
        // Your existing mounted lifecycle hook
    },
    beforeMount() {
        // Your existing beforeMount lifecycle hook
    },
    beforeDestroy() {
        window.removeEventListener('keydown', this.handleKeyPress);
        window.removeEventListener('resize', this.arrangeCardList);
    },
    methods: {
        // Your existing methods
    }
}
</script>
  
<style scoped>
ul {
    list-style-type: disc;
    padding-left: 20px;
}

li {
    margin-bottom: 5px;
}

.container {
    width: 100%;
    /* Ensure the container takes the full width */
    padding: 20px 20px;
    /* Optional padding for better layout on smaller screens */

    .game-overview {
        width: 100%;
        /* Ensure game-overview takes the full width */
        max-width: 1200px;
        /* Optional max-width to limit the width on larger screens */
        margin: 0 auto;
        /* Center the content horizontally */



        .game-overview-title {
            p {
                color: var(--Primary-colour, #2EA8AF);
                font-family: "Bebas Neue";
                font-size: 48px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }

        .game-overview-description {
            p {
                color: #000;
                font-family: "Inter";
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;
                text-wrap: wrap;
            }

            .game-overview-subtitle {
                color: #000;
                font-family: "Inter";
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;
                text-wrap: wrap;
                padding-bottom: 10px;
            }

        }

    }
}
</style>
  