import qs from "qs";
import axios from "axios";
import { messages } from "@/i18n/data";
// Configure env files .
// Test server
// const baseURL = "http://atmapi.5544444455.com";
// Local Server
// const baseURL = 'http://localhost:8080/atm';
// Production
const baseURL = 'https://webapi.atm.network';

// axios.defaults.baseURL = baseURL;
axios.defaults.withCredentials = true;
// axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
//news
export const fetchNewsList = async (
  pageIndex = null,
  pageSize = null,
  type = ""
) => {
  try {
    const params = new URLSearchParams({
      pageIndex,
      pageSize,
      type,
    });
    const requestOptions = {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Cssg-Language": "en",
      },
      body: params,
    };

    const response = await fetch(`${baseURL}/atm/getNewsList`, requestOptions);
    const result = await response.json();

    return result;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const showDefaultImageIfEmpty = (news) => {
  if (!news.coverImg || news.coverImg.trim() === "") {
    news.coverImg = "images/junkNews.png";
  }
};

//proposal

export const getInitiateList = async () => {
  try {
    const params = new URLSearchParams({
      pageIndex: 1,
      pageSize: 1,
    });

    const requestOptions = {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Cssg-Language": "en",
      },
      body: params.toString(),
    };

    const response = await fetch(`/community/getInitiateList`, requestOptions);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const result = await response.json();

    return result;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

//overview
export const getOverviewData = async () => {
  try {
    const response = await axios.get(`/atm/overview`, {
      withCredentials: true,
    });

    return response.data.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

//subscribe
export const subscribe = async (email) => {
  try {
    const response = await axios.post(
      `/atm/emailSubscription`,
      { email },
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

//auth apis
export const getSignMessage = async (address) => {
  try {
    const response = await axios.post(
      `/user/getSignMessage`,
      { address: address },
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cssg-Language": "en",
        },
      }
    );

    return {
      data: response.data.data.signMessage,
      isError: false,
      message: "success"
    };
  } catch (error) {
    throw {
      data: response.data.signMessage,
      isError: true,
      message:error
    };;
  }
};


export const getLoginToken = async (address, sign) => {
  try {
    const response = await axios.post(
      `/user/appLogin`,
      {
				address: address,
				sign,
				deviceId: 112233,
				clientType: 6,//	string	1:IOS App Store版本 2:IOS企业版 3:Android Google Play版本 4:Android 普通版本 5:PC端 6官网端(必填)
				clientVersion: 1,
			},
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cssg-Language": "en",
        },
      }
    );
    return {
      data: response.data.data,
      isError: false,
      message: "success"
    };
  } catch (error) {
    throw {
      data: response.data.signMessage,
      isError: true,
      message:error
    };;
  }
};
